.logo-accent {
    width: 150px;
    position: absolute;
    left: 70px;
    top:-54px;
    z-index:0;
}

.header h1 {
    font-size: 42px;
    font-weight: 800;
    color: var(--main);
    display: block;
    width: 125px;
    height: 125px;
    padding: 5px;
    border: 5px solid var(--accent);
    line-height: 41px;
    text-transform: capitalize;
    margin: 0;
    background: var(--light);
    z-index: 1;
    text-align: left;
    box-sizing: content-box;
}
