:root {
    --main: #201E50;
    --secondary: #7AC74F;
    --accent: #F7B32B;
    --light: #f3f5f6;
    --black: #030301;
}

body {
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-variation-settings: "wdth" 100;
    background-color: var(--light);
    color: var(--black);
    font-size: 18px;
}

h2 {
    text-transform: uppercase;
    font-size: 32px;
    color: var(--main);
}

h3 {
    display: block;
    padding: 4px;
}

.highlighted {
    background: var(--accent);
    color: var(--main);
    font-weight: bold;
    padding: 4px;
}

.wrapper {
    position: relative;
    margin: 40px auto;
    padding: 20px 0;
    max-width: 1200px;
}

.styled-container {
    width: 100%;
    background-color: #242162;
    background-image: linear-gradient(45deg, transparent 33.3%, var(--main) 33.3%, var(--main) 66.6%, transparent 66.6%),
    linear-gradient(-45deg, transparent 33.3%, var(--main) 33.3%, var(--main) 66.6%, transparent 66.6%);
    background-size: 60px 60px;
}

@media (max-width: 1200px) {
    .wrapper {
        padding: 0 20px;
    }
}

@media (max-width: 768px) {
    .wrapper {
        padding: 0 15px;
    }
}

@media (max-width: 576px) {
    .wrapper {
        padding: 0 10px;
    }
}
