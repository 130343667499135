/* Basic styling for error page */
.error-page {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f7f7f7;
    color: #333;
    font-family: Arial, sans-serif;
}

.error-container {
    text-align: center;
    animation: fadeIn 1s ease-out; /* Fade-in effect */
}

.error-code {
    font-size: 8rem;
    color: #900; /* Main color for error code */
    margin: 0;
    font-weight: bold;
}

.error-message {
    font-size: 1.5rem;
    color: #555;
    margin: 20px 0;
}

.error-button {
    display: inline-block;
    padding: 10px 20px;
    margin-top: 20px;
    color: var(--secondary);
    background-color: var(--main);
    text-decoration: none;
    font-weight: bold;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.error-button:hover {
    background-color: var(--secondary);
    color: var(--main)
}

/* Animation for fade-in effect */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Responsive styling for smaller screens */
@media (max-width: 600px) {
    .error-code {
        font-size: 5rem;
    }

    .error-message {
        font-size: 1.2rem;
    }
}
